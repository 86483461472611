import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';

import AuthReducer from '../auth/authReducer';
import DashboardReducer from '../dashboard/dashboardReducer';
import TabReducer from '../common/tab/tabReducer';
import UsuarioReducer from '../usuario/usuarioReducer';
import DispositivoReducer from '../dispositivo/dispositivoReducer';

const rootReducer = combineReducers({
	auth: AuthReducer,
    dashboard: DashboardReducer,
    usuario: UsuarioReducer,
	dispositivo: DispositivoReducer,
    tab: TabReducer,
    form: formReducer,
    toastr: toastrReducer
});

export default rootReducer;
