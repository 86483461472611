import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector } from "redux-form";
import DateFormat from "../common/dateFormat/DateFormat";

import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardHeader from "../common/template/contentCardHeader";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import Tr from "../common/table/tr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import ButtonTable from "../common/table/buttonTable";
import Button from "../common/button/button";
import Form from "./usuarioForm";

import { setModoTela, initForm, salvar, excluir, getLista } from "./usuarioActions";

class Usuario extends Component {
	state = {
		linhaSelecionada: null,
	};

	componentWillMount() {
		this.props.getLista();
	}

	render() {
		return (
			<div>
				<Content>
					{this.props.modoTela == "cadastro" ? <Form onSubmit={this.props.salvar} /> : null}
					{this.props.modoTela == "exclusao" ? <Form excluir onSubmit={this.props.excluir} /> : null}
					{this.props.modoTela == "lista" ? this.lista() : null}
				</Content>
			</div>
		);
	}

	removerDupliados(lista) {
		const listaFiltrada = [];
		for (const item of lista) {
			if (!listaFiltrada.find((itemLista) => itemLista.id == item.id)) listaFiltrada.push(item);
		}
		return listaFiltrada;
	}

	lista() {
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols="6 6 4 2">
							<Button
								text="Adicionar"
								type={"success"}
								icon={"fa fa-plus"}
								event={() => {
									this.props.setModoTela("cadastro", {});
									this.props.initForm({
										usuario: true,
										ponto_atendimento: false,
										vendedor: false,
									});
								}}
							/>
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Table responsive>
						<THead>
							<Tr>
								<Th>Nome</Th>
								<Th>Email</Th>
								<Th>Telefone</Th>
								<Th>CPF</Th>
								<Th>Data de Criação</Th>
								<Th>Tipo</Th>
								<Th>Bloqueado</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{this.removerDupliados(this.props.lista).map((item) => {
								const cpfComPontuacao = item.cpf.split("");
								cpfComPontuacao.splice(3, 0, ".");
								cpfComPontuacao.splice(7, 0, ".");
								cpfComPontuacao.splice(11, 0, "-");

								const telefoneComPontuacao = item.telefone
									? `(${item.telefone.substring(0, 2)}) ${item.telefone.substring(2, 7)}-${item.telefone.substring(7)}`
									: "";

								return (
									<Tr
										key={item.id}
										className={`${this.state.linhaSelecionada == item.id ? "hover" : ""}`}
										onClick={() =>
											this.setState({
												...this.state,
												linhaSelecionada: item.id,
											})
										}
										onMouseEnter={() =>
											this.setState({
												...this.state,
												linhaSelecionada: item.id,
											})
										}
									>
										<Td>{item.nome}</Td>
										<Td>{item.email}</Td>
										<Td>{telefoneComPontuacao}</Td>
										<Td>{cpfComPontuacao}</Td>
										<Td>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_inclusao)}</Td>
										{/* <Td>{item.administrador ? "Admin" : "Cliente"}</Td> */}
										<Td>{item.nome_tipo_dispositivo}</Td>
										<Td>{item.bloqueado ? "Sim" : "Não"}</Td>
										<Td alignRight minWidth={100}>
											<ButtonTable
												type={"warning"}
												icon={"fas fa-pencil-alt"}
												visible={this.state.linhaSelecionada == item.id}
												event={() => {
													this.props.setModoTela("cadastro", {
														...item,
														senha: null,
													});
													this.props.initForm({
														...item,
														senha: null,
													});
												}}
											/>

											<ButtonTable
												type={"danger"}
												icon={"fas fa-trash-alt"}
												visible={this.state.linhaSelecionada == item.id}
												event={() => {
													this.props.setModoTela("exclusao", {
														...item,
														senha: null,
													});
													this.props.initForm({
														...item,
														senha: null,
													});
												}}
											/>
										</Td>
									</Tr>
								);
							})}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}
}

const mapStateToProps = (state) => ({
	sessao: state.auth.usuario,
	modoTela: state.usuario.modoTela,
	lista: state.usuario.lista,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Usuario);
