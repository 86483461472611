import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, getFormValues } from "redux-form";

import ContentCard from "../common/template/contentCard";
import ContentCardBody from "../common/template/contentCardBody";
import ContentCardFooter from "../common/template/contentCardFooter";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";
import Form from "../common/form/form";
import LabelAndInput from "../common/form/labelAndInput";

import { setModoTela } from "./dispositivoActions";

class DispositivoForm extends Component {
	componentWillMount() {}

	render() {
		return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Field name="tipo_nome" component={LabelAndInput} label="Tipo" placeholder="Informe o Tipo" cols="12 12 12 12" readOnly="readOnly" />

						<Field name="serie" component={LabelAndInput} label="Série" placeholder="Informe a Série" cols="12 12 12 12" readOnly="readOnly" />
						<>
							<Field
								name="usuario"
								component={LabelAndInput}
								label="Usuário"
								placeholder="Informe o Usuário"
								cols="12 12 12 12"
								readOnly="readOnly"
							/>
							<Field name="senha" component={LabelAndInput} label="Senha" placeholder="Informe a Senha" cols="12 12 12 12" readOnly="readOnly" />
						</>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter={this.props.excluir}>
							{this.props.excluir ? (
								<Grid cols="6 6 4 3">
									<Button text="Excluir" submit type={"danger"} icon={"fa fa-trash"} />
								</Grid>
							) : null}
							<Grid cols="6 6 4 3">
								<Button text="Voltar" type={"warning"} icon={"fa fa-chevron-left"} event={() => this.props.setModoTela("lista")} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
		);
	}
}

DispositivoForm = reduxForm({
	form: "dispositivoForm",
	destroyOnUnmount: false,
})(DispositivoForm);
const mapStateToProps = (state) => ({
	sessao: state.auth.dispositivo,
	registro: state.dispositivo.registro,
	formularioValues: getFormValues("dispositivoForm")(state),
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ setModoTela }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DispositivoForm);
