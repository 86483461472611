import React, { Component } from 'react';
import { HashRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import Header from '../common/template/header';
import SideBar from '../common/template/sideBar';
import Footer from '../common/template/footer';
import Messages from '../common/msg/messages';

import Routes from './routes';

class App extends Component {

	render() {
		return (
			<HashRouter>
				<div className='wrapper'>
					<Header />
					<SideBar />
					<Routes />
					<Messages />
				</div>
			</HashRouter>
		);
	}
}


const mapStateToProps = state => ({
	sessao: state.auth.usuario
});
export default connect(mapStateToProps, null)(App);
