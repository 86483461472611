const INITIAL_STATE = { dadosServidor: {}, dadosGrafico: [] };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case "DASHBOARD_DADOS_SERVIDOR":
      return {
        ...state,
        dadosServidor: action.payload.data,
      };
    case "DASHBOARD_DADOS_GRAFICO":
      return {
        ...state,
        dadosGrafico: action.payload.data,
      };

    default:
      return state;
  }
}
