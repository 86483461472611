import axios from "axios";
import { showTabs, selectTab } from "../common/tab/tabActions";
import consts from "../consts";
import { setErroAPI, setSuccess } from "../common/toast/toast";
import { reset as resetForm, initialize } from "redux-form";

export function getDadosServidor() {
  const request = axios.get(`${consts.API_URL}/admin/servidor`);
  return {
    type: "DASHBOARD_DADOS_SERVIDOR",
    payload: request,
  };
}

export function getDadosGrafico() {
  const request = axios.get(`${consts.API_URL}/admin/grafico`);
  return {
    type: "DASHBOARD_DADOS_GRAFICO",
    payload: request,
  };
}
