import "./auth.css";
import React, { Component } from "react";
import { reduxForm, Field, getFormValues } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { login, signup, initForm } from "./authActions";
import Row from "../common/layout/row";
import If from "../common/operator/if";
import Messages from "../common/msg/messages";
import Input from "../common/form/inputAuth";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { createMuiTheme } from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";
import { ThemeProvider } from "@material-ui/styles";
import imagemLogo from "../assets/images/logo.png";

class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = { loginMode: true };
  }

  changeMode() {
    this.setState({ loginMode: !this.state.loginMode });
  }

  onSubmit(values) {
    const { login, signup } = this.props;
    this.state.loginMode ? login(values) : signup(values);
  }

  getStyles() {
    return makeStyles((theme) => ({
      paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
      },
      form: {
        width: "100%",
        marginTop: theme.spacing(1),
      },
      submit: {
        margin: theme.spacing(3, 0, 2),
      },
    }));
  }

  getTheme() {
    return createMuiTheme({
      palette: {
        primary: {
          // light: será calculada com base em palette.primary.main,
          main: "#4d805b",
          // dark: será calculada com base em palette.primary.main,
          // contrastText: será calculada para contrastar com palette.primary.main
        },
        secondary: {
          light: "#0066ff",
          main: "#0044ff",
          // dark: será calculada com base palette.secondary.main,
          contrastText: "#ffcc00",
        },
        // Usado por `getContrastText()` para maximizar o contraste entre
        // o plano de fundo e o texto.
        contrastThreshold: 3,
        // Usado pelas funções abaixo para mudança de uma cor de luminância por aproximadamente
        // dois índices dentro de sua paleta tonal.
        // Por exemplo, mude de Red 500 para Red 300 ou Red 700.
        tonalOffset: 0.2,
      },
    });
  }

  render() {
    const classes = this.getStyles();

    const { loginMode } = this.state;
    const { handleSubmit } = this.props;

    return (
      <ThemeProvider theme={this.getTheme()}>
        <Container
          component="main"
          maxWidth="xs"
          style={{
            display: "flex",
            marginTop: -150,
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <CssBaseline />
          <div className={classes.paper}>
            <img
              src={imagemLogo}
              style={{
                width: 250,
                marginTop: 30,
                marginLeft: 65,
                marginRight: 65,
                marginBottom: 30,
              }}
            />
            <Typography component="h1" variant="h5">
              Entrar
            </Typography>
            <form
              className={classes.form}
              noValidate
              onSubmit={handleSubmit((v) => this.onSubmit(v))}
            >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="login"
                label="Email"
                name="login"
                autoComplete="login"
                autoFocus
                onChange={(e) => {
                  this.props.initForm({
                    ...this.props.formularioValues,
                    login: e.target.value,
                  });
                }}
              />

              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="senha"
                label="Senha"
                type="password"
                id="senha"
                autoComplete="current-password"
                onChange={(e) => {
                  this.props.initForm({
                    ...this.props.formularioValues,
                    senha: e.target.value,
                  });
                }}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Entrar
              </Button>
            </form>
          </div>
          <Snackbar open={this.props.message.open}>
            <MuiAlert severity={this.props.message.type} variant="filled">
              {this.props.message.text}
            </MuiAlert>
          </Snackbar>
        </Container>
      </ThemeProvider>
    );
  }
}

Auth = reduxForm({ form: "authForm" })(Auth);
const mapStateToProps = (state) => ({
  message: state.auth.message,
  formularioValues: getFormValues("authForm")(state),
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ login, signup, initForm }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Auth);
