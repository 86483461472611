import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardBody from "../common/template/contentCardBody";
import ValueBox from "../common/widget/valueBox";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import locale from "@amcharts/amcharts4/lang/pt_BR.js";

import { getDadosServidor, getDadosGrafico } from "./dashboardActions";

class Dashboard extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.props.getDadosServidor();
    // this.props.getDadosGrafico();
  }

  render() {
    this.montarGrafico(this.props.dadosGrafico);
    return (
      <Content>
        <ContentCard>
          <ContentCardBody>
            <Grid container>
              {this.mostrarDadosServidor(this.props.dadosServidor)}
            </Grid>
            <div
              id={`grafico`}
              style={{
                width: "100%",
                // minWidth: "1150px",
                paddingTop: 15,
                backgroundColor: "#fff",
                height: "500px",
              }}
            ></div>
          </ContentCardBody>
        </ContentCard>
      </Content>
    );
  }

  mostrarDadosServidor(obj) {
    const listaDados = [];
    for (let prop in obj) {
      const descricao = obj[prop].split(":")[0];
      const valor = obj[prop].split(":")[1];
      listaDados.push(
        <ValueBox cols="12 12 6 3" value={descricao} text={valor} />
      );
    }
    return <Row>{listaDados}</Row>;
  }
  //deixa botoes da barra de rolagem do gráfico quadrados
  customizeChartGrip(grip) {
    // Remove default grip image
    grip.icon.disabled = true;

    // Disable background
    grip.background.disabled = true;

    // Add rotated rectangle as bi-di arrow
    const img = grip.createChild(am4core.Rectangle);
    img.width = 15;
    img.height = 15;
    img.fill = am4core.color("#0070cc");
    img.stroke = am4core.color("#0070cc");
    img.rotation = 45;
    img.align = "center";
    img.valign = "middle";

    // Add vertical bar
    const line = grip.createChild(am4core.Rectangle);
    line.height = 60;
    line.width = 3;
    line.fill = am4core.color("#0070cc");
    line.stroke = am4core.color("#0070cc");
    line.align = "center";
    line.valign = "middle";
  }

  montarGrafico(dadosGrafico) {
    am4core.useTheme(am4themes_animated);

    let chart = am4core.create("grafico", am4charts.XYChart);
    // const subtitle = chart.titles.create();
    // subtitle.text = this.props.registro.nome_unidade_consumidora;
    // subtitle.fontWeight = "bold";
    // subtitle.fontSize = window.innerWidth < 1150 ? "0.7rem" : "1rem";
    // subtitle.fill = am4core.color("#000");
    // subtitle.marginBottom = 30;

    // const title = chart.titles.create();
    // title.text = "Histórico";
    // title.fontWeight = "bold";
    // title.fontSize = window.innerWidth < 1150 ? "0.8rem" : "1.2rem";
    // title.fill = am4core.color("#000");
    // title.marginBottom = 30;

    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.filePrefix = "Gráfico";
    chart.exporting.menu.align = "right";
    chart.exporting.menu.verticalAlign = "top";
    chart.exporting.menu.items = [
      {
        label: "...",
        menu: [
          {
            type: "png",
            label: "PNG",
          },
        ],
      },
    ];
    chart.language.locale = locale;
    chart.language.locale["_decimalSeparator"] = ",";
    chart.language.locale["_thousandSeparator"] = ".";
    chart.numberFormatter.numberFormat = "###,###,###,###.00";

    // Create axes
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "tipo";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 10;
    categoryAxis.startLocation = 0.0;
    categoryAxis.endLocation = 1.0;
    categoryAxis.renderer.labels.template.fill = am4core.color("#000"); //cor do texto eixo x
    categoryAxis.renderer.maxLabelPosition = 0.95;
    categoryAxis.renderer.minLabelPosition = 0.05;

    //controla o espaço entre as categorias (grupo de colunas)
    categoryAxis.renderer.cellStartLocation = 0.2;
    categoryAxis.renderer.cellEndLocation = 0.8;

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    if (chart.yAxes.indexOf(valueAxis) != 0) {
      valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
    }
    valueAxis.min = 0;
    valueAxis.renderer.labels.template.fill = am4core.color("#000"); //cor do texto eixo y

    // Create series dispositivos_cadastrados
    const dispositivos_cadastrados = chart.series.push(
      new am4charts.ColumnSeries()
    );
    dispositivos_cadastrados.dataFields.valueY = "dispositivos_cadastrados";
    dispositivos_cadastrados.dataFields.categoryX = "tipo";
    dispositivos_cadastrados.name = "Dispositivos Cadastrados";
    dispositivos_cadastrados.columns.template.tooltipText = `Dispositivos do Tipo "{categoryX}" Cadastrados: [bold]{valueY.formatNumber("############")}[/]`;
    dispositivos_cadastrados.strokeWidth = 2;
    dispositivos_cadastrados.stacked = true;
    dispositivos_cadastrados.stroke = am4core.color("#eb1");
    dispositivos_cadastrados.fill = am4core.color("#eb1");
    dispositivos_cadastrados.fillOpacity = 0.2;
    //faz as barras ficar lado a lado, se colocar true elas ficam empilhadas
    dispositivos_cadastrados.stacked = false;

    // Create series dispositivos_cadastrados
    const dispositivos_online = chart.series.push(new am4charts.ColumnSeries());
    dispositivos_online.dataFields.valueY = "dispositivos_online";
    dispositivos_online.dataFields.categoryX = "tipo";
    dispositivos_online.name = "Dispositivos On-line";
    dispositivos_online.columns.template.tooltipText = `Dispositivos do Tipo "{categoryX}" On-line: [bold]{valueY.formatNumber("############")}[/]`;
    dispositivos_online.strokeWidth = 2;
    dispositivos_online.stacked = true;
    dispositivos_online.stroke = am4core.color("#595");
    dispositivos_online.fill = am4core.color("#595");
    dispositivos_online.fillOpacity = 0.2;
    //faz as barras ficar lado a lado, se colocar true elas ficam empilhadas
    dispositivos_online.stacked = false;

    // Create series dispositivos_cadastrados
    const total_usuarios = chart.series.push(new am4charts.ColumnSeries());
    total_usuarios.dataFields.valueY = "total_usuarios";
    total_usuarios.dataFields.categoryX = "tipo";
    total_usuarios.name = "Total de Usuários";
    total_usuarios.columns.template.tooltipText = `Total de Usuários do Tipo "{categoryX}": [bold]{valueY.formatNumber("############")}[/]`;
    total_usuarios.strokeWidth = 2;
    total_usuarios.stacked = true;
    total_usuarios.stroke = am4core.color("#29f");
    total_usuarios.fill = am4core.color("#29f");
    total_usuarios.fillOpacity = 0.2;
    //faz as barras ficar lado a lado, se colocar true elas ficam empilhadas
    total_usuarios.stacked = false;

    //Estilo da scrollbar
    chart.scrollbarX = new am4charts.XYChartScrollbar();
    chart.scrollbarX.series.push(dispositivos_cadastrados);
    chart.scrollbarX.series.push(dispositivos_online);
    chart.scrollbarX.series.push(total_usuarios);
    chart.scrollbarX.unselectedOverlay.fill = am4core.color("#fff");
    chart.scrollbarX.unselectedOverlay.fillOpacity = 0.7;
    chart.scrollbarX.unselectedOverlay.stroke = am4core.color("#fff");

    chart.scrollbarX.background.fill = am4core.color("#fff");
    chart.scrollbarX.thumb.background.fill = am4core.color("#2190ff"); //cor do trecho selecionado
    chart.scrollbarX.thumb.background.fillOpacity = 0.3; //opacidade da cor do trecho selecionado
    chart.scrollbarX.stroke = am4core.color("#003040"); // cor do texto dentro do trecho seleionado
    chart.scrollbarX.strokeWidth = 0;

    //Cor da scrollbar ao passar o mouse
    chart.scrollbarX.startGrip.background.states.getKey(
      "hover"
    ).properties.fill = am4core.color("#2190ff");
    chart.scrollbarX.startGrip.background.states.getKey(
      "hover"
    ).properties.fillOpacity = 0.3;

    chart.scrollbarX.endGrip.background.states.getKey("hover").properties.fill =
      am4core.color("#2190ff");
    chart.scrollbarX.endGrip.background.states.getKey(
      "hover"
    ).properties.fillOpacity = 0.3;

    chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill =
      am4core.color("#2190ff");
    chart.scrollbarX.thumb.background.states.getKey(
      "hover"
    ).properties.fillOpacity = 0.3;

    //Adiciona legenda
    chart.legend = new am4charts.Legend();
    chart.legend.labels.template.fill = am4core.color("#000");

    //Adiciona cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = categoryAxis;
    chart.cursor.snapToSeries = [
      dispositivos_cadastrados,
      dispositivos_online,
      total_usuarios,
    ];

    //configura a cor do tooltip x
    const categoryAxisTooltip = categoryAxis.tooltip;
    categoryAxisTooltip.background.fill = am4core.color("#0070cc");
    categoryAxisTooltip.stroke = am4core.color("#fff");
    categoryAxisTooltip.background.strokeWidth = 0;
    categoryAxisTooltip.background.cornerRadius = 3;
    categoryAxisTooltip.background.pointerLength = 0;
    categoryAxisTooltip.dy = 5;

    //configura a cor do tooltip y
    const valueAxisTooltip = valueAxis.tooltip;
    valueAxisTooltip.background.fill = am4core.color("#0070cc");
    valueAxisTooltip.stroke = am4core.color("#fff");
    valueAxisTooltip.background.strokeWidth = 0;
    valueAxisTooltip.background.cornerRadius = 3;
    valueAxisTooltip.background.pointerLength = 0;
    valueAxisTooltip.dy = 5;

    // valueAxis.renderer.columns.strokeOpacity = 1;
    // valueAxis.renderer.columns.strokeWidth = 2;

    chart.data = dadosGrafico;

    //deixa botoes da barra de rolagem quadrados
    this.customizeChartGrip(chart.scrollbarX.startGrip);
    this.customizeChartGrip(chart.scrollbarX.endGrip);

    this.chart = chart;
  }
}

const mapStateToProps = (state) => ({
  sessao: state.auth.usuario,
  dadosServidor: state.dashboard.dadosServidor,
  dadosGrafico: [
    {
      tipo: "Aquário",
      dispositivos_cadastrados: 110,
      dispositivos_online: 107,
      total_usuarios: 86,
    },
    {
      tipo: "Base",
      dispositivos_cadastrados: 102,
      dispositivos_online: 97,
      total_usuarios: 76,
    },
    {
      tipo: "Dosadora",
      dispositivos_cadastrados: 30,
      dispositivos_online: 26,
      total_usuarios: 12,
    },
    {
      tipo: "Estação",
      dispositivos_cadastrados: 10,
      dispositivos_online: 8,
      total_usuarios: 4,
    },
  ],
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDadosServidor,
      getDadosGrafico,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
