import React from "react";
import MenuItem from "./menuItem";
import MenuTree from "./menuTree";

export default (props) => (
  <nav className="mt-2">
    <ul
      className="nav nav-pills nav-sidebar flex-column nav-flat nav-compact"
      data-widget="treeview"
      role="menu"
      data-accordion="false"
    >
      <MenuItem path="" label="Dashboard" icon="fas fa-chart-bar" />
      <MenuItem path="usuario" label="Usuário" icon="fas fa-users" />
      <MenuItem
        path="dispositivo"
        label="Dispositivo"
        icon="fas fa-microchip"
      />
    </ul>
  </nav>
);
