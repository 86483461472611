import React, { useState } from "react";

export default (props) => {
	const [hover, setHover] = useState(false);

	return props.type ? ( //normal
		<button
			type={props.submit ? "submit" : "button"}
			className={`btn btn-block btn-${props.small ? "xs" : "lg"} btn-outline-${props.type}`}
			onClick={props.event}
			disabled={props.disabled}
		>
			{props.text}
		</button>
	) : (
		<button
			style={{ ...props.style, backgroundColor: hover ? props.style.color : "", color: hover ? "#fff" : props.style.color }}
			type={props.submit ? "submit" : "button"}
			className={`btn btn-block btn-${props.small ? "xs" : "lg"} btn-outline-${props.type}`}
			onClick={props.event}
			disabled={props.disabled}
			onMouseOver={() => setHover((prev) => !prev)}
			onMouseOut={() => setHover((prev) => !prev)}
		>
			{props.text}
		</button>
	);
};

/*
primary
secondary
success
danger
warning
info
light
dark
link
*/
