import React from "react";
import Grid from "../layout/grid";

export default (props) => (
  <Grid cols={props.cols}>
    <div
      className={`small-box bg-${props.color}`}
      style={{ height: window.innerWidth < 992 ? null : 100 }}
    >
      <div className="inner">
        <h6>{props.value}</h6>
        <p style={{ marginBottom: 0 }}>{props.text}</p>
      </div>
      <div className="icon">
        <i className={`fa fa-${props.icon}`}></i>
      </div>
    </div>
  </Grid>
);
