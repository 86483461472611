import { DateTime } from "luxon";

export default class DateFormat {
  static getDataAtual() {
    return DateTime.now().toFormat("yyyy/LL/dd");
  }

  static getDataHoraAtual() {
    return DateTime.now().toFormat("yyyy/LL/dd HH:mm");
  }

  static getDataInicioMesAtual() {
    return `${DateTime.now().toFormat("yyyy/LL")}/01`;
  }

  static getTimestampParaData(timestamp) {
    return !timestamp
      ? null
      : DateTime.fromMillis(timestamp).toFormat("yyyy/LL/dd");
  }

  static getTimestampParaDataHora(timestamp) {
    return !timestamp
      ? null
      : DateTime.fromMillis(timestamp).toFormat("yyyy/LL/dd HH:mm");
  }

  static formatarDataSqlParaTela(data) {
    return !data
      ? null
      : (DateTime.fromISO(data).isValid
          ? DateTime.fromISO(data, { zone: "utc" })
          : DateTime.fromFormat(data, "yyyy/LL/dd")
        ).toFormat("dd/LL/yyyy");
  }

  static formatarDataHoraSqlParaTela(dataHora, ano = true) {
    if (ano) {
      return !dataHora
        ? null
        : (DateTime.fromISO(dataHora).isValid
            ? DateTime.fromISO(dataHora, { zone: "utc" })
            : DateTime.fromFormat(dataHora, "yyyy/LL/dd HH:mm")
          ).toFormat("dd/LL/yyyy HH:mm");
    } else {
      return !dataHora
        ? null
        : (DateTime.fromISO(dataHora).isValid
            ? DateTime.fromISO(dataHora, { zone: "utc" })
            : DateTime.fromFormat(dataHora, "yyyy/LL/dd HH:mm")
          ).toFormat("dd/LL HH:mm");
    }
  }

  static formatarDataTelaParaSql(data) {
    return !data
      ? null
      : DateTime.fromFormat(data, "dd/LL/yyyy").toFormat("yyyy/LL/dd");
  }

  static formatarDataHoraTelaParaSql(dataHora) {
    return !dataHora
      ? null
      : DateTime.fromFormat(dataHora, "dd/LL/yyyy HH:mm").toFormat(
          "yyyy/LL/dd HH:mm"
        );
  }

  static formatarDataTelaParaParametro(data) {
    return !data
      ? null
      : (DateTime.fromISO(data).isValid
          ? DateTime.fromISO(data)
          : DateTime.fromFormat(data, "dd/LL/yyyy")
        ).toFormat("yyyy-LL-dd");
  }
}
