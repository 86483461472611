import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardHeader from "../common/template/contentCardHeader";
import ContentCardBody from "../common/template/contentCardBody";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import Tr from "../common/table/tr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import ButtonTable from "../common/table/buttonTable";
import Button from "../common/button/button";
import Form from "./dispositivoForm";

import { setModoTela, initForm, salvar, excluir, getLista } from "./dispositivoActions";

class Dispositivo extends Component {
	state = {
		linhaSelecionada: null,
	};

	componentWillMount() {
		this.props.getLista();
	}

	render() {
		return (
			<div>
				<Content>
					{this.props.modoTela == "cadastro" ? <Form onSubmit={this.props.salvar} /> : null}
					{this.props.modoTela == "exclusao" ? <Form excluir onSubmit={this.props.excluir} /> : null}
					{this.props.modoTela == "lista" ? this.lista() : null}
				</Content>
			</div>
		);
	}

	lista() {
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols="3 3 3 3" style={{ display: "flex" }}>
							<Button
								text="Adicionar Aquário"
								type={"success"}
								icon={"fa fa-plus"}
								event={() => {
									this.props.salvar({
										id_tipo: 1,
									});
								}}
							/>
						</Grid>
						<Grid cols="3 3 3 3" style={{ display: "flex" }}>
							<Button
								text="Adicionar Base"
								type={"primary"}
								icon={"fa fa-plus"}
								event={() => {
									this.props.salvar({
										id_tipo: 2,
									});
								}}
							/>
						</Grid>
						<Grid cols="3 3 3 3" style={{ display: "flex" }}>
							<Button
								text="Adicionar Dosadora"
								type={"warning"}
								icon={"fa fa-plus"}
								event={() => {
									this.props.salvar({
										id_tipo: 3,
									});
								}}
							/>
						</Grid>
						<Grid cols="3 3 3 3">
							<Button
								text="Adicionar Estação - Node"
								style={{ border: "1px solid #F81", color: "#F81" }}
								icon={"fa fa-plus"}
								event={() => {
									this.props.salvar({
										id_tipo: 4,
									});
								}}
							/>
							{/* </Grid>
						<Grid cols="3 3 3 3"> */}
							<Button
								text="Adicionar Estação - Gateway"
								type={"danger"}
								icon={"fa fa-plus"}
								event={() => {
									this.props.salvar({
										id_tipo: 5,
									});
								}}
							/>
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Table responsive>
						<THead>
							<Tr>
								<Th>Tipo</Th>
								<Th>Usuário MQTT</Th>
								<Th>Senha MQTT</Th>
								<Th>Série</Th>
								<Th>Usuário</Th>
								<Th>E-mail</Th>
								<Th>CPF</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.lista.map((item) => {
								let cpfComPontuacao = null;
								if (item.usuario_cpf) {
									cpfComPontuacao = item.usuario_cpf.split("");
									cpfComPontuacao.splice(3, 0, ".");
									cpfComPontuacao.splice(7, 0, ".");
									cpfComPontuacao.splice(11, 0, "-");
								}
								return (
									<Tr
										key={item.id}
										className={`${this.state.linhaSelecionada == item.id ? "hover" : ""}`}
										onClick={() =>
											this.setState({
												...this.state,
												linhaSelecionada: item.id,
											})
										}
										onMouseEnter={() =>
											this.setState({
												...this.state,
												linhaSelecionada: item.id,
											})
										}
									>
										<Td>{item.tipo_nome}</Td>
										<Td>{item.usuario}</Td>
										<Td>{item.senha}</Td>
										<Td>{item.serie}</Td>
										<Td>{item.usuario_nome}</Td>
										<Td>{item.usuario_email}</Td>
										<Td>{cpfComPontuacao}</Td>
										<Td alignRight minWidth={100}>
											<ButtonTable
												type={"danger"}
												icon={"fas fa-trash-alt"}
												visible={this.state.linhaSelecionada == item.id}
												event={() => {
													this.props.setModoTela("exclusao", {
														...item,
														senha: null,
													});
													this.props.initForm({
														...item,
														senha: null,
													});
												}}
											/>
										</Td>
									</Tr>
								);
							})}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}
}

const mapStateToProps = (state) => ({
	sessao: state.auth.dispositivo,
	modoTela: state.dispositivo.modoTela,
	lista: state.dispositivo.lista,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Dispositivo);
